<template>
  <div>
    <title-bar :title-stack="titleStack"/>
    <section class="section is-main-section">
      <b-tabs v-model="activeTab" @input="clearFormTabs()">
        <b-tab-item label="Filtres">
          <div class="columns">
            <div class="column">
              <ul class="groups" v-if="!!loadData">
                <li class="group field" :key="g.idGroupeCritereRecherche" v-for="g in filtersNotCategorie" :class="{selected: g.selected}">
                  <div class="level group-header" style="margin-top: 1.5rem;">
                    <div class="level-left">
                      <div class="level-item tag is-medium">
                        <div class="view">
                          <label @click="selectGroupFilter(g)">{{ g.libGroupeCritereRecherche }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="taginput">
                    <div class="taginput-container is-focusable">
                      <div class="tag" :key="f.idCritereRecherche" v-for="f in g.critereRecherche" :class="{selected: f.selected}">
                        <div class="view">
                          <label @click="selectFilter(f)">{{ f.libCritere }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="column sticky" style="margin-top: 42px;">
              <b-field :label="l.alias" :key="l.idLangue" v-for="l in langues">
                <b-input v-model="l.value" :name="l.idLangue" @blur="editTranslation($event)"></b-input>
              </b-field>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="Pictos">
          <div class="columns">
            <div class="column">
              <ul class="groups" v-if="!!loadData">
                <li class="group field" :key="c.idCategoriePicto" v-for="c in pictos" :class="{selected: c.selected}">
                  <div class="level group-header" style="margin-top: 1.5rem;">
                    <div class="level-left">
                      <div class="level-item tag is-medium">
                        <div class="view">
                          <label @click="selectCategoriePicto(c)">{{ c.libCategoriePicto }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="taginput">
                    <div class="taginput-container is-focusable">
                      <div class="tag" :key="p.idPicto" v-for="p in c.picto" :class="{selected: p.selected}">
                        <div class="view">
                          <label @click="selectPicto(p)">{{ p.alias }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="column sticky" style="margin-top: 42px;">
              <b-field :label="l.alias" :key="l.idLangue" v-for="l in langues">
                <b-input v-model="l.value" :name="l.idLangue" @blur="editTranslation($event)"></b-input>
              </b-field>
            </div>
          </div>
        </b-tab-item>
      </b-tabs>
    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'

const $httpRacineApi = 'https://www.sainte-maxime.com'

export default {
  name: 'Translations',
  components: { TitleBar },
  data () {
    return {
      activeTab: 0,
      langues: [],
      loadData: false,
      typeSelected: '',
      filters: [],
      filterSelected: '',
      oldFilterSelected: '',
      pictos: [],
      pictoSelected: '',
      oldPictoSelected: ''
    }
  },
  mounted: function () {
    var self = this
    fetch($httpRacineApi + '/translations/getLangues')
      .then((response) => response.json())
      .then((jsonResponse) => {
        const lang = jsonResponse
        self.langues = lang.filter(z => z.libLangue !== 'fr').map(lg => ({ ...lg, value: '' }))
      })
      .catch(function (e) {
        console.error(e)
      })

    fetch($httpRacineApi + '/filters/getFiltres')
      .then((response) => response.json())
      .then((jsonResponse) => {
        self.filters = jsonResponse
        self.loadData = true
      })
      .catch(function (e) {
        console.error(e)
      })

    fetch($httpRacineApi + '/pictos/getPictos')
      .then((response) => response.json())
      .then((jsonResponse) => {
        self.pictos = jsonResponse
        self.loadData = true
      })
      .catch(function (e) {
        console.error(e)
      })
  },
  methods: {
    clearInputArray (inputArray) {
      inputArray.forEach(item => {
        item.value = ''
      })
    },
    clearFormTabs () {
      this.clearInputArray(this.langues)
      this.oldFilterSelected.selected = false
    },
    editTranslation (event) {
      const lib = event.target.value
      const idLangue = event.target.name

      if (this.typeSelected === 'critereRecherche') {
        fetch($httpRacineApi + '/translations/editTradCritereRecherche', {
          method: 'POST',
          body: JSON.stringify({
            idCritereRecherche: this.filterSelected.idCritereRecherche,
            idLangue: idLangue,
            libTradCritereRecherche: lib
          }),
          headers: {
            'Content-type': 'application/json; chartset=UTF-8'
          }
        })
          .then(response => response.json())
          .then((jsonResponse) => {
          })
      } else if (this.typeSelected === 'groupeCritereRecherche') {
        fetch($httpRacineApi + '/translations/editTradGroupeCritereRecherche', {
          method: 'POST',
          body: JSON.stringify({
            idGroupeCritereRecherche: this.filterSelected.idGroupeCritereRecherche,
            idLangue: idLangue,
            libTradGroupeCritereRecherche: lib
          }),
          headers: {
            'Content-type': 'application/json; chartset=UTF-8'
          }
        })
          .then(response => response.json())
          .then((jsonResponse) => {
          })
      } else if (this.typeSelected === 'picto') {
        fetch($httpRacineApi + '/translations/editTradPicto', {
          method: 'POST',
          body: JSON.stringify({
            idPicto: this.pictoSelected.idPicto,
            idLangue: idLangue,
            libTradPicto: lib
          }),
          headers: {
            'Content-type': 'application/json; chartset=UTF-8'
          }
        })
          .then(response => response.json())
          .then((jsonResponse) => {
          })
      } else if (this.typeSelected === 'categoriePicto') {
        fetch($httpRacineApi + '/translations/editTradCategoriePicto', {
          method: 'POST',
          body: JSON.stringify({
            idCategoriePicto: this.pictoSelected.idCategoriePicto,
            idLangue: idLangue,
            libTradCategoriePicto: lib
          }),
          headers: {
            'Content-type': 'application/json; chartset=UTF-8'
          }
        })
          .then(response => response.json())
          .then((jsonResponse) => {
          })
      }
    },
    selectFilter (f) {
      f.selected = true
      if (!!this.oldFilterSelected && this.oldFilterSelected !== f) this.oldFilterSelected.selected = false
      this.filterSelected = f
      this.oldFilterSelected = this.filterSelected
      this.clearInputArray(this.langues)

      this.typeSelected = 'critereRecherche'

      fetch(`${$httpRacineApi}/translations/getTradCritereRecherche`, {
        method: 'POST',
        body: JSON.stringify({
          idCritereRecherche: f.idCritereRecherche
        }),
        headers: {
          'Content-type': 'application/json; chartset=UTF-8'
        }
      })
        .then(response => response.json())
        .then((jsonResponse) => {
          this.langues.forEach(lg => {
            const filter = jsonResponse.filter(t => t.idLangue === lg.idLangue)
            if (filter.length > 0) {
              lg.value = filter[0].lib
            }
          })
        })
    },
    selectGroupFilter (g) {
      // console.log(g)
      g.selected = true
      if (!!this.oldFilterSelected && this.oldFilterSelected !== g) this.oldFilterSelected.selected = false
      this.filterSelected = g
      this.oldFilterSelected = this.filterSelected
      this.clearInputArray(this.langues)

      this.typeSelected = 'groupeCritereRecherche'

      fetch(`${$httpRacineApi}/translations/getTradGroupeCritereRecherche`, {
        method: 'POST',
        body: JSON.stringify({
          idGroupeCritereRecherche: g.idGroupeCritereRecherche
        }),
        headers: {
          'Content-type': 'application/json; chartset=UTF-8'
        }
      })
        .then(response => response.json())
        .then((jsonResponse) => {
          this.langues.forEach(lg => {
            const filter = jsonResponse.filter(t => t.idLangue === lg.idLangue)
            if (filter.length > 0) {
              lg.value = filter[0].lib
            }
          })
        })
    },
    selectPicto (p) {
      p.selected = true
      if (!!this.oldPictoSelected && this.oldPictoSelected !== p) this.oldPictoSelected.selected = false
      this.pictoSelected = p
      this.oldPictoSelected = this.pictoSelected
      this.clearInputArray(this.langues)

      this.typeSelected = 'picto'

      fetch(`${$httpRacineApi}/translations/getTradPicto`, {
        method: 'POST',
        body: JSON.stringify({
          idPicto: p.idPicto
        }),
        headers: {
          'Content-type': 'application/json; chartset=UTF-8'
        }
      })
        .then(response => response.json())
        .then((jsonResponse) => {
          this.langues.forEach(lg => {
            const picto = jsonResponse.filter(t => t.idLangue === lg.idLangue)
            if (picto.length > 0) {
              lg.value = picto[0].lib
            }
          })
        })
    },
    selectCategoriePicto (c) {
      // console.log(c)
      c.selected = true
      if (!!this.oldPictoSelected && this.oldPictoSelected !== c) this.oldPictoSelected.selected = false
      this.pictoSelected = c
      this.oldPictoSelected = this.pictoSelected
      this.clearInputArray(this.langues)

      this.typeSelected = 'categoriePicto'

      fetch(`${$httpRacineApi}/translations/getTradCategoriePicto`, {
        method: 'POST',
        body: JSON.stringify({
          idCategoriePicto: c.idCategoriePicto
        }),
        headers: {
          'Content-type': 'application/json; chartset=UTF-8'
        }
      })
        .then(response => response.json())
        .then((jsonResponse) => {
          this.langues.forEach(lg => {
            const picto = jsonResponse.filter(t => t.idLangue === lg.idLangue)
            if (picto.length > 0) {
              lg.value = picto[0].lib
            }
          })
        })
    }
  },
  computed: {
    filtersNotCategorie: function () {
      return this.filters.filter(g => g.idGroupeCritereRecherche !== 7)
    },
    titleStack () {
      return [
        'Traductions'
      ]
    }
  }
}
</script>
<style scoped>
  .columns {
    align-items: flex-start;
  }
  .sticky {
    position: sticky;
    top: 50px;
  }

  .tag.selected {
    background-color: #7957d5;
    color: #fff;
  }
  .group.selected .level-item.tag {
    background-color: #7957d5;
    color: #fff;
  }
</style>
