<template>
  <div>
    <title-bar :title-stack="titleStack"/>
    <section class="section is-main-section">
      <div class="columns">
        <div class="column">
            <b-field>
              <b-select placeholder="Ajouter une sélection" @input="addLayout($event)">
                <optgroup v-for="(opt, index) in opts" :key="index" :label="opt.label">
                  <option
                    v-for="(o) in opt.children"
                    :value="o.key"
                    :key="o.key">
                    {{ o.label }}
                </option>
                </optgroup>
              </b-select>
            </b-field>
            <b-collapse class="card" animation="slide" v-for="(l, index) of layout" :key="index" :open="false" @open="isOpen = index">
              <div slot="trigger" slot-scope="props" class="card-header" role="button" :class="{ selected: l.selected }">
                <p class="card-header-title">{{ l.lib }}</p>
                <a class="card-header-icon">
                  <b-icon pack="fal" :icon="props.open ? 'caret-up' : 'caret-down'" size="is-small"></b-icon>
                </a>
              </div>
              <div class="card-content">
                <div class="content">
                  <div class="columns">
                    <div class="column">
                      <b-field label="Theme">
                        <b-input v-model="l.theme">{{ l.theme }}</b-input>
                      </b-field>
                      <b-field label="Type d'affichage de la liste de résultat">
                        <b-select v-model="l.type" expanded>
                          <option value="grid">Grille</option>
                          <option value="address">Adresse</option>
                          <option value="list">Liste</option>
                          <option value="slide">Slide</option>
                          <option value="slideOne">Carousel</option>
                        </b-select>
                      </b-field>
                      <b-field label="Type d'affichage des filtres de résultats">
                        <b-select v-model="l.typeFiltre" expanded>
                          <option value="horizontal">Horizontal</option>
                          <option value="vertical">Vertical</option>
                        </b-select>
                      </b-field>
                      <b-field label="Mode d'affichage de la liste de résultat">
                        <b-select v-model="l.layoutMode" expanded>
                          <option value="list">Grille</option>
                          <option value="map">Mixte</option>
                          <option value="fullmap">Carte</option>
                        </b-select>
                      </b-field>
                      <b-field label="Type de pagination">
                        <b-select v-model="l.typePaginate" expanded>
                          <option value="INFINITE">Infinie</option>
                          <option value="PAGER">Classique</option>
                          <option value="NONE">Aucune</option>
                        </b-select>
                      </b-field>
                      <b-field label="Masonry Mode (Obsolète)">
                        <b-select v-model="l.masonryMode" expanded>
                          <option>fitRows</option>
                          <option>masonry</option>
                          <option>vertical</option>
                        </b-select>
                      </b-field>
                      <b-field label="Filtres de tri">
                        <b-input v-model="l.filter">{{ l.filter }}</b-input>
                      </b-field>
                      <b-field label="Tri par défaut">
                        <b-input v-model="l.tri">{{ l.tri }}</b-input>
                      </b-field>
                      <b-field label="Hauteur de la carte">
                        <b-input v-model="l.mapHeight">{{ l.mapHeight }}</b-input>
                      </b-field>
                      <b-field label="Nombre par page">
                        <b-input v-model="l.nbPerPage">{{ l.nbPerPage }}</b-input>
                      </b-field>
                      <b-field label="Groupes de filtres à masquer">
                        <b-input v-model="l.groupeIdsToHide">{{ l.groupeIdsToHide }}</b-input>
                      </b-field>
                      <b-field label="Groupes de filtres à ouvrir">
                        <b-input v-model="l.groupeIdsToOpen">{{ l.groupeIdsToOpen }}</b-input>
                      </b-field>
                    </div>
                    <div class="column">
                      <div class="field">
                        <b-switch v-model="l.showCart">Afficher le panier</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showFiltreResults">Affiche le bouton de recherche</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showFiltrePopover">Affiche les filtres de recherche dans un popup</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showFiltreSidebar">Affiche les filtres de recherche dans un panneau lattéral</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showFiltreLib">Affiche la recherche par libellé</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showFiltreTri">Affiche le filtre de tri</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showFiltreGroupes">Affiche les groupes de filtres</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showFiltreDate">Affiche les critères de recherche par date</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showFiltre">Afficher les filtres</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showFiltreAge">Afficher le filtre âge</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showFiltrePrice">Afficher le filtre prix</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showFiltreCapacity">Afficher le filtre capacité</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showFiltreNombreChambres">Afficher le filtre nombre de chambres</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showFiltreNombreJours">Afficher le filtre nombres de jours</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showFiltreNombreNuits">Afficher le filtre nombre de nuits</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showFiltreDistance">Afficher le filtre distance</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showFiltreDuration">Afficher le filtre durée</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showFiltreElevation">Afficher le filtre dénivelé</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showFiltreGeolocalisation">Afficher le filtre de géolocalisation</b-switch>
                      </div>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <p class="card-header-title">Affichage de la liste de résultat</p>
                      <div class="field">
                        <b-switch v-model="l.showPrestas">Afficher les résultats</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestasAddress">Afficher l'adresse</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestasAttributes">Afficher les équipements</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestasBreadcrumb">Afficher le fil d'ariane</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestasCalendar">Afficher le calendrier</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestasCart">Afficher la mise au panier</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestasCategorie">Afficher la catégorie</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestasContact">Afficher les infos de contact</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestasCoordonnee">Afficher les coordonnées</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestasCover">Afficher une image de couverture</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestasDate">Afficher la date</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestasDescription">Afficher la description courte</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestasDispo">Afficher la date générée automatiquement</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestasGallery">Afficher une galerie d'image</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestasFairGuest">Afficher les avis clients</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestasFairGuestLite">Afficher les avis clients abrégés</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestasLogo">Afficher le logo</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestasMap">Afficher la carte</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestasMapClosed">Afficher les objets à proximité</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestasMapCluster">Activer le clustering sur la carte</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestasMapControls">Afficher les controles de la carte</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestasMapZoomScroll">Activer le zoom au scroll sur la carte</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestasObjetsLies">Afficher les objets liés</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestasOpened">Afficher l'ouverture du jour</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestasTarif">Afficher les tarifs</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestasTitle">Afficher le titre</b-switch>
                      </div>
                    </div>
                    <div class="column">
                      <p class="card-header-title">Affichage de la fiche produit</p>
                      <div class="field">
                        <b-switch v-model="l.showPrestaView">Afficher la fiche produit dans la liste de résultat</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestaAddress">Afficher l'adresse</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestaAttributes">Afficher les équipements</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestaBack">Afficher le bouton de retour en arrière</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestaBreadcrumb">Afficher le fil d'ariane</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestaCalendar">Afficher le calendrier</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestaCart">Afficher la mise au panier</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestaCategorie">Afficher la catégorie</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestaContact">Afficher les infos de contact</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestaCoordonnee">Afficher les coordonnées</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestaCover">Afficher une image de couverture</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestaDate">Afficher la date</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestaDescription">Afficher la description courte</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestaDispo">Afficher la date générée automatiquement</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestaGallery">Afficher une galerie d'image</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestaFairGuest">Afficher les avis clients</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestaFairGuestLite">Afficher les avis clients abrégés</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestaLogo">Afficher le logo</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestaMap">Afficher la carte</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestaMapClosed">Afficher les objets à proximité</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestaMapCluster">Activer le clustering sur la carte</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestaMapControls">Afficher les controles de la carte</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestaMapZoomScroll">Activer le zoom au scroll sur la carte</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestaObjetsLies">Afficher les objets liés</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestaOpened">Afficher l'ouverture du jour</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestaTarif">Afficher les tarifs</b-switch>
                      </div>
                      <div class="field">
                        <b-switch v-model="l.showPrestaTitle">Afficher le titre</b-switch>
                      </div>
                    </div>
                  </div>
                  <div class="buttons">
                    <b-button type="is-danger" @click="deleteLayout(l.idLayout)">
                      <i class="fal fa-trash-alt"></i> Effacer
                    </b-button>
                    <b-button type="is-success" @click="editLayout(l)">
                      <i class="fal fa-save"></i> Enregistrer
                    </b-button>
                  </div>
                </div>
              </div>
            </b-collapse>
        </div>
    </div>
    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'

import _ from 'lodash'

const $httpRacineApi = 'https://www.sainte-maxime.com'

export default {
  name: 'Layout',
  components: { TitleBar },
  data () {
    return {
      layout: [],
      opts: [],
      oldLayoutelected: '',
      critereRecherche: '',
      critereRechercheCodeTif: [],
      idsCritereRechercheCodeTif: [],
      thesaurusSelected: [],
      thesaurusTypeSelected: [],
      newGroupLayout: '',
      newCritereRecherche: [],
      editingGroupLayout: null,
      editingLayout: null,
      loadData: false,
      f: null,
      thesaurus: null,
      thesaurusType: null,
      isOpen: false
    }
  },
  mounted: function () {
    var self = this
    fetch($httpRacineApi + '/layout/getLayout')
      .then((response) => response.json())
      .then((jsonResponse) => {
        // console.log(jsonResponse)
        self.layout = jsonResponse
        self.loadData = true

        fetch($httpRacineApi + '/services/getCode')
          .then((response) => response.json())
          .then((jsonResponse) => {
            // console.log(jsonResponse)
            const codes = jsonResponse
            self.loadData = true
            Object.entries(codes).forEach(function (code) {
              const [k, v] = code
              const children = []
              v.forEach(function (c) {
                if (!self.layoutIds.includes(c.code)) {
                  children.push({ key: c.code, label: c.label, value: c.value })
                }
              })
              self.opts.push({ label: k, value: '', disabled: true, children: children })
            })
          })
          .catch(function (e) {
            console.error(e)
          })
      })
      .catch(function (e) {
        console.error(e)
      })
  },
  // watch: {
  //   value (value) {
  //     this.layout = value
  //   }
  // },
  methods: {
    getLayoutedThesaurus (thesaurus, type) {
      // console.log(_.layout(thesaurus, {'type': type}))
      return _.layout(thesaurus, { type: type })
    },
    addLayout (event) {
      console.log(event)
      const idSelections = event
      if (!idSelections) {
        return
      }
      fetch($httpRacineApi + '/layout/addLayout', {
        method: 'POST',
        body: JSON.stringify({
          idSelections: idSelections
        }),
        headers: {
          'Content-type': 'application/json; chartset=UTF-8'
        }
      })
        .then(response => response.json())
        .then((jsonResponse) => {
          document.location.reload()
        })
      // console.log(this.layout)
    },
    deleteLayout: function (idLayout) {
      fetch($httpRacineApi + '/layout/deleteLayout', {
        method: 'POST',
        body: JSON.stringify({
          idLayout: idLayout
        }),
        headers: {
          'Content-type': 'application/json; chartset=UTF-8'
        }
      })
      this.layout = this.layout.filter(x => x.idLayout !== idLayout)
      this.isOpen = false
    },

    editLayout: function (l) {
      // console.log(l)
      if (!l) {
        return
      }
      fetch($httpRacineApi + '/layout/editLayout', {
        method: 'POST',
        body: JSON.stringify(l),
        headers: {
          'Content-type': 'application/json; chartset=UTF-8'
        }
      })
        .then(response => response.json())
        .then((jsonResponse) => {
          if (jsonResponse.idLayout) {
            this.$buefy.snackbar.open({
              message: 'Enregistrement',
              queue: false
            })
          }
        })
    }
  },
  directives: {
  },
  computed: {
    layoutIds: function () {
      return this.layout.map(l => l.code)
    },
    // layoutNotIdLayout: function () {
    //   return this.layout.filter(l => !l.idLayout)
    // },
    // layoutWithIdLayout: function () {
    //   return this.layout.filter(l => l.idLayout)
    // },
    titleStack () {
      return [
        'Disposition'
      ]
    }
  },
  filters: {
    toBoolean: value => {
      if (!value) return ''
      return Boolean(value)
    }
  }
}
</script>
<style scoped>
  .card-header-title {
      font-size: 1rem;
      font-weight: 600 !important;
  }
  .card {
      padding: 0;
      margin: 0;
      border: 0;
  }

  select {
      background: none;
  }
</style>
