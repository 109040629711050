<template>
  <div>
    <title-bar :title-stack="titleStack"/>
    <section class="section is-main-section">
      <div class="columns">
        <div class="column">
          <div class="field">
            <div class="control has-icons-left is-clearfix">
              <input type="text" class="input" style="border: 0; box-shadow: none;" placeholder="Ajouter un groupe de filtre" v-model="newGroupFilter" @keyup.enter="addGroupFilter">
              <span class="icon is-left"><i class="fa-solid fa-layer-plus"></i></span>
            </div>
          </div>
          <ul class="groups" v-if="!!loadData">
            <li class="group field" :key="g.idGroupeCritereRecherche" v-for="g in filtersNotCategorie" :class="{editing: g === editingGroupFilter}">
              <div class="level group-header" style="margin-top: 1.5rem;">
                <div class="level-left">
                    <!-- <input type="checkbox" v-model="g.completed" class="input"> -->
                    <div class="level-item">
                      <div class="view">
                        <label class="label" @dblclick="editGroupFilter(g)">{{ g.libGroupeCritereRecherche }}</label>
                      </div>
                      <input type="text" class="editGroupFilter" v-model="g.libGroupeCritereRecherche" @keyup.enter="doneEditGroupFilter(g)" @blur="doneEditGroupFilter(g)" @keyup.esc="cancelEditGroupFilter(g)" v-focus="g === editingGroupFilter">
                    </div>
                    <div class="level-item field">
                      <b-switch size="is-small" @input="editJoinWidthAndGroupFilter(g, $event)" :value="(g.joinWidthAnd == 1) ? true : false">et/ou</b-switch>
                    </div>
                </div>
                <div class="level-right">
                  <div class="level-item">
                    <button class="move" @click="moveUpGroupFilter(g.idGroupeCritereRecherche, filtersNotCategorie)"><i class="fa-light fa-angle-up"></i></button>
                  </div>
                  <div class="level-item">
                    <button class="move" @click="moveDownGroupFilter(g.idGroupeCritereRecherche, filtersNotCategorie)"><i class="fa-light fa-angle-down"></i></button>
                  </div>
                  <div class="level-item">
                    <button class="destroy" @click="deleteGroupFilter(g)"><i class="fa-light fa-times-circle"></i></button>
                  </div>
                </div>
              </div>
              <div class="taginput" v-sortable="{ sortableOptions, onUpdate: orderFilter }">
                <div class="taginput-container is-focusable">
                  <div class="tag" :name="'filter-' + f.idCritereRecherche" :key="f.idCritereRecherche" v-for="f in g.critereRecherche" :class="{selected: f.selected, completed: f.completed, editing: f === editingFilter}">
                    <div class="view">
                      <label @click="selectFilter(f,g)" @dblclick="editFilter(f)">{{ f.libCritere }}</label>
                      <button class="destroy" @click="deleteFilter(f,g)"><i class="fa-light fa-times"></i></button>
                    </div>
                    <input type="text" class="editFilter" v-model="f.libCritere" @keyup.enter="doneEditFilter(f)" @blur="doneEditFilter(f)" @keyup.esc="cancelEditFilter(f)" v-focus="f === editingFilter">
                  </div>
                  <div>
                    <div class="control has-icons-left is-clearfix">
                      <input type="text" class="input" style="padding-top: 0; padding-bottom: 0; border: 0; box-shadow: none;" autofocus v-model="newCritereRecherche[g.idGroupeCritereRecherche]" @keyup.enter="addFilter(g.idGroupeCritereRecherche)" placeholder="Ajouter un filtre">
                      <span class="icon is-left"><i class="fa-light fa-layer-plus"></i></span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="column" style="margin-top: 42px;">
          <b-collapse class="card" animation="slide" v-for="(type, index) of thesaurusType" :key="index" :open="false" @open="isOpen = index">
            <template #trigger="props">
              <div class="card-header" role="button" :class="{ selected: type.selected }">
                <p class="card-header-title">{{ type.type | spaceUppercase }}</p>
                <a class="card-header-icon">
                  <b-icon pack="fal" :icon="props.open ? 'angle-up' : 'angle-down'" size="is-small"></b-icon>
                </a>
              </div>
            </template>
            <div class="card-content">
              <ul class="list is-hoverable">
                <li class="list-item is-small thesaurus" :class="{ selected: t.selected }" :key="t.id" v-for="t in getFilteredThesaurus(thesaurus, type.type)">
                  <input :id="'codeApidae-' + t.codeApidae" type="checkbox" :value="t.codeApidae" :data-label="t.labelFR" v-model.number="idsCritereRechercheCodeTif" class="toggle" @click="changeFilterCodeTif($event)">
                  <label :for="'codeApidae-' + t.codeApidae">{{ t.labelFR }} <span v-if="t.parent">({{ t.parent }})</span></label>
                </li>
              </ul>
            </div>
          </b-collapse>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'

import _ from 'lodash'
import Sortable from 'sortablejs'
import Vue from 'vue'

const $httpRacineApi = 'https://www.sainte-maxime.com'

const createSortable = (el, options) => {
  return Sortable.create(el, {
    ...options
  })
}

/**
* We add a new instance of Sortable when the element
* is bound or updated, and destroy it when it's unbound.
*/
const sortable = {
  name: 'sortable',
  bind (el, binding) {
    const container = el.querySelector('.taginput-container')
    container._sortable = createSortable(container, binding.value)
  },
  update (el, binding) {
    const container = el.querySelector('.taginput-container')
    container._sortable.destroy()
    container._sortable = createSortable(container, binding.value)
  },
  unbind (el) {
    const container = el.querySelector('.taginput-container')
    container._sortable.destroy()
  }
}

export default {
  name: 'Filters',
  components: { TitleBar },
  data () {
    return {
      filters: [],
      oldFilterSelected: '',
      groupeCritereRecherche: '',
      critereRecherche: '',
      critereRechercheCodeTif: [],
      idsCritereRechercheCodeTif: [],
      thesaurusSelected: [],
      thesaurusTypeSelected: [],
      newGroupFilter: '',
      newCritereRecherche: [],
      editingGroupFilter: null,
      editingFilter: null,
      sortableOptions: {
        chosenClass: 'is-primary',
        draggable: '.tag',
        filter: '.ignore-elements'
      },
      loadData: false,
      f: null,
      thesaurus: null,
      thesaurusType: null,
      isOpen: false
    }
  },
  mounted: function () {
    var self = this
    fetch($httpRacineApi + '/filters/getFiltres')
      .then((response) => response.json())
      .then((jsonResponse) => {
        // console.log(jsonResponse)
        self.filters = jsonResponse
        self.loadData = true
      })
      .catch(function (e) {
        console.error(e)
      })

    fetch($httpRacineApi + '/thesaurus/getThesaurus')
      .then((response) => response.json())
      .then((jsonResponse) => {
        //   console.log(jsonResponse)
        // self.thesaurusType = _.keys(
        //   _.countBy(jsonResponse, function (jsonResponse) {
        //     return jsonResponse.type;
        //   })
        // );
        const key = 'type'
        var thesaurusTypeTemp = [...new Map(jsonResponse.map(item =>
          [item[key], item])).values()]
        self.thesaurusType = thesaurusTypeTemp.map(({ type }) => ({ type }))
        // console.log(self.thesaurusType)

        // jsonResponse.map( index => index.selected=false)
        self.thesaurus = jsonResponse
      })
      .catch(function (e) {
        console.error(e)
      })
  },
  // watch: {
  //   value (value) {
  //     this.filters = value
  //   }
  // },
  methods: {
    getFilteredThesaurus (thesaurus, type) {
      // console.log(_.filter(thesaurus, { type: type }))
      return _.filter(thesaurus, { type: type })
    },

    changeFilterCodeTif (event) {
      // console.log(event)
      const codeApidae = event.target.value
      const libTif = event.target.getAttribute('data-label') ?? this.critereRecherche.libCritere

      if (event.target.checked) { // addFilterCodeTif
        // console.log('addFilterCodeTif')
        fetch($httpRacineApi + '/filters/addCritereRechercheCodeTif', {
          method: 'POST',
          body: JSON.stringify({
            idCritereRecherche: this.critereRecherche.idCritereRecherche,
            libTif: libTif,
            codeApidae: codeApidae
          }),
          headers: {
            'Content-type': 'application/json; chartset=UTF-8'
          }
        })
          .then(response => response.json())
          .then((jsonResponse) => {
            this.filters.filter(f => f.idGroupeCritereRecherche === this.groupeCritereRecherche.idGroupeCritereRecherche)[0].critereRecherche.filter(c => c.idCritereRecherche === this.critereRecherche.idCritereRecherche)[0].critereRechercheCodeTif.push({
              checked: false,
              codeApidae: parseInt(codeApidae),
              codeTif: null,
              idCritereRechercheCodeTif: parseInt(jsonResponse.idCritereRechercheCodeTif),
              libTif: libTif
            })
          })
      } else { // removeFilterCodeTif
        // console.log('removeFilterCodeTif')
        fetch($httpRacineApi + '/filters/removeCritereRechercheCodeTif', {
          method: 'POST',
          body: JSON.stringify({
            idCritereRecherche: this.critereRecherche.idCritereRecherche,
            codeApidae: codeApidae
          }),
          headers: {
            'Content-type': 'application/json; chartset=UTF-8'
          }
        })
          .then(response => response.json())
          .then((jsonResponse) => {
            const index = this.filters.filter(f => f.idGroupeCritereRecherche === this.groupeCritereRecherche.idGroupeCritereRecherche)[0].critereRecherche.filter(c => c.idCritereRecherche === this.critereRecherche.idCritereRecherche)[0].critereRechercheCodeTif.findIndex(o => {
              return o.codeApidae === parseInt(codeApidae)
            })
            this.filters.filter(f => f.idGroupeCritereRecherche === this.groupeCritereRecherche.idGroupeCritereRecherche)[0].critereRecherche.filter(c => c.idCritereRecherche === this.critereRecherche.idCritereRecherche)[0].critereRechercheCodeTif.splice(index, 1)
          })
      }
    },

    addFilter (idGroupeCritereRecherche) {
      var value = this.newCritereRecherche[idGroupeCritereRecherche] && this.newCritereRecherche[idGroupeCritereRecherche].trim()
      if (!value) {
        return
      }
      fetch($httpRacineApi + '/filters/addCritereRecherche', {
        method: 'POST',
        body: JSON.stringify({
          libCritere: value,
          idGroupeCritereRecherche: idGroupeCritereRecherche
        }),
        headers: {
          'Content-type': 'application/json; chartset=UTF-8'
        }
      })
        .then(response => response.json())
        .then((jsonResponse) => {
          this.filters.filter(f => f.idGroupeCritereRecherche === idGroupeCritereRecherche)[0].critereRecherche.push({
            idCritereRecherche: jsonResponse.idCritereRecherche,
            libCritere: value,
            critereRechercheCodeTif: [],
            selected: false
          })
        })
        // console.log(this.filters)
      this.newCritereRecherche = []
    },
    selectFilter (f, g) {
      // console.log(f)
      this.groupeCritereRecherche = g

      f.selected = true
      if (!!this.oldFilterSelected && this.oldFilterSelected !== f) this.oldFilterSelected.selected = false
      this.oldFilterSelected = f

      this.critereRecherche = f
      this.critereRechercheCodeTif = f.critereRechercheCodeTif
      this.idsCritereRechercheCodeTif = this.critereRechercheCodeTif.map(q => parseInt(q.codeApidae))
      // console.log(this.idsCritereRechercheCodeTif)
      // console.log(this.thesaurusType)

      this.thesaurusSelected.map(t => { t.selected = false }) // delete old
      this.thesaurusSelected = this.thesaurus.filter((h) => this.idsCritereRechercheCodeTif.includes(parseInt(h.codeApidae)))
      this.thesaurusSelected.forEach(t => {
        t.selected = true
      })
      // console.log(this.thesaurusSelected)
      this.thesaurusType.map(t => { t.selected = false }) // delete old
      this.thesaurusTypeSelected = this.thesaurusSelected.map(m => m.type)
      this.thesaurusType.forEach(s => {
        if (this.thesaurusTypeSelected.includes(s.type)) {
          s.selected = true
        }
      })
      // console.log(this.thesaurusTypeSelected)
      // console.log(this.thesaurusType)
    },
    deleteFilter: function (f, g) {
      fetch($httpRacineApi + '/filters/deleteCritereRecherche', {
        method: 'POST',
        body: JSON.stringify({
          idCritereRecherche: f.idCritereRecherche
        }),
        headers: {
          'Content-type': 'application/json; chartset=UTF-8'
        }
      })
      const indexFilter = this.filters.filter(x => x.idGroupeCritereRecherche === g.idGroupeCritereRecherche)[0].critereRecherche.findIndex(x => x.idCritereRecherche === f.idCritereRecherche)
      this.filters.filter(x => x.idGroupeCritereRecherche === g.idGroupeCritereRecherche)[0].critereRecherche.splice(indexFilter, 1)
    },
    editFilter (f) {
      this.beforeEditCache = f.libCritere
      this.editingFilter = f
    },
    doneEditFilter: function (f) {
      if (!this.editingFilter) {
        return
      }
      this.editingFilter = null
      f.libCritere = f.libCritere.trim()
      if (!f.libCritere) {
        this.deleteFilter(f)
      }
      fetch($httpRacineApi + '/filters/editCritereRecherche', {
        method: 'POST',
        body: JSON.stringify({
          libCritere: f.libCritere,
          idCritereRecherche: f.idCritereRecherche
        }),
        headers: {
          'Content-type': 'application/json; chartset=UTF-8'
        }
      })
    },
    cancelEditFilter: function (f) {
      this.editingFilter = null
      f.libCritere = this.beforeEditCache
    },
    orderFilter (event) {
      const orderArray = []
      const elements = event.target.childNodes
      elements.forEach(function (element) {
        if (element.firstChild !== null && element.hasAttribute('name')) {
          orderArray.push(element.getAttribute('name').replace('filter-', ''))
        }
      })
      const orderString = orderArray.join('-')
      fetch($httpRacineApi + '/filters/orderCritereRecherche', {
        method: 'POST',
        body: JSON.stringify({
          orderFilters: orderString
        }),
        headers: {
          'Content-type': 'application/json; chartset=UTF-8'
        }
      })
    },
    addGroupFilter () {
      var value = this.newGroupFilter && this.newGroupFilter.trim()
      if (!value) {
        return
      }
      fetch($httpRacineApi + '/filters/addGroupeCritereRecherche', {
        method: 'POST',
        body: JSON.stringify({
          libGroupeCritereRecherche: value
        }),
        headers: {
          'Content-type': 'application/json; chartset=UTF-8'
        }
      })
        .then(response => response.json())
        .then((jsonResponse) => {
          this.filters = Object.assign({}, this.filters, [{
            critereRecherche: [],
            idGroupeCritereRecherche: jsonResponse.idGroupeCritereRecherche,
            libGroupeCritereRecherche: value,
            alias: value,
            joinWidthAnd: '0',
            tri: '0'
          }])
          document.location.reload()
        })
      this.newGroupFilter = ''
    },
    deleteGroupFilter: function (g) {
      // console.log(this.filters.indexOf(g))
      // console.log(g)
      if (!g.idGroupeCritereRecherche) {
        return
      }
      fetch($httpRacineApi + '/filters/deleteGroupeCritereRecherche', {
        method: 'POST',
        body: JSON.stringify({
          idGroupeCritereRecherche: g.idGroupeCritereRecherche
        }),
        headers: {
          'Content-type': 'application/json; chartset=UTF-8'
        }
      })
        .then(() => this.filters.splice(this.filters.indexOf(g), 1))
    },
    editGroupFilter (g) {
      this.beforeEditCache = g.libGroupeCritereRecherche
      this.editingGroupFilter = g
    },
    doneEditGroupFilter: function (g) {
      if (!this.editingGroupFilter) {
        return
      }
      this.editingGroupFilter = null
      g.libGroupeCritereRecherche = g.libGroupeCritereRecherche.trim()
      if (!g.libGroupeCritereRecherche) {
        this.deleteFilter(g)
      }

      fetch($httpRacineApi + '/filters/editGroupeCritereRecherche', {
        method: 'POST',
        body: JSON.stringify({
          libGroupeCritereRecherche: g.libGroupeCritereRecherche,
          idGroupeCritereRecherche: g.idGroupeCritereRecherche
        }),
        headers: {
          'Content-type': 'application/json; chartset=UTF-8'
        }
      })
    },
    cancelEditGroupFilter: function (g) {
      this.editingGroupFilter = null
      g.libGroupeCritereRecherche = this.beforeEditCache
    },
    moveUpGroupFilter (id, filtersNotCategorie) {
      const filtersIds = filtersNotCategorie.map(filter => filter.idGroupeCritereRecherche)

      const fromIndex = filtersIds.indexOf(id)
      const toIndex = (fromIndex > 0) ? fromIndex - 1 : fromIndex

      const element = filtersIds.splice(fromIndex, 1)[0]
      filtersIds.splice(toIndex, 0, element)

      this.orderGroupFilter(filtersIds)
    },
    moveDownGroupFilter (id, filtersNotCategorie) {
      const filtersIds = filtersNotCategorie.map(filter => filter.idGroupeCritereRecherche)

      const fromIndex = filtersIds.indexOf(id)
      const toIndex = fromIndex + 1

      const element = filtersIds.splice(fromIndex, 1)[0]
      filtersIds.splice(toIndex, 0, element)

      this.orderGroupFilter(filtersIds)
    },
    orderGroupFilter (orderArray) {
      const orderString = orderArray.join('-')
      fetch($httpRacineApi + '/filters/orderGroupeCritereRecherche', {
        method: 'POST',
        body: JSON.stringify({
          orderGroupsFilters: orderString
        }),
        headers: {
          'Content-type': 'application/json; chartset=UTF-8'
        }
      })
        .then((response) => response.json())
        .then((jsonResponse) => {
          // console.log(jsonResponse)
          this.filters = jsonResponse
          this.loadData = true
        })
        .catch(function (e) {
          console.error(e)
        })
    },
    editJoinWidthAndGroupFilter (g, event) {
      if (!g.idGroupeCritereRecherche) {
        return
      }
      fetch($httpRacineApi + '/filters/editJoinWidthAndGroupeCritereRecherche', {
        method: 'POST',
        body: JSON.stringify({
          joinWidthAnd: event,
          idGroupeCritereRecherche: g.idGroupeCritereRecherche
        }),
        headers: {
          'Content-type': 'application/json; chartset=UTF-8'
        }
      })
    }
  },
  directives: {
    focus (el, value) {
      if (value) {
        Vue.nextTick(() => {
          el.focus()
        })
      }
    },

    sortable
  },
  computed: {
    filtersNotCategorie: function () {
      return this.filters.filter(g => g.idGroupeCritereRecherche !== 7)
    },
    titleStack () {
      return [
        'Filtres'
      ]
    }
  },
  filters: {
    spaceUppercase: value => {
      if (!value) return ''
      return value.replace(/([A-Z])/g, ' $1').trim()
    }
  }
}
</script>
<style scoped>
.apidae_page_apidae_filters_plugin_filters {
  background-color: transparent;
}

.fa-layer-plus {
  width: 21px;
  }

/* GroupFilter */
.group .level-item button {
  cursor: pointer;
}
.group .group-header .editGroupFilter {
  display: none;
}
.group .group-header .view {
  display: flex;
}
.group.editing .group-header .editGroupFilter {
  display: block;
  min-height: 24px;
  line-height: 1;
}
.group.editing .group-header .view {
  display: none;
}

/* Filter */
.tag.selected {
  background-color: #7957d5;
  color: #fff;
}
.tag.selected i {
  color: #fff;
}
 .editFilter {
  display: none;
}
.tag .view {
  display: flex;
}
.tag.editing .editFilter {
  display: block;
  min-height: 24px;
  line-height: 1;
}
.tag.editing .view {
  display: none;
}

.card-header-title {
  font-size: 1rem;
  font-weight: 600 !important;
}
.card {
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
}
.switch input[type=checkbox]+.check:before {
  width: 16px;
  height: 16px;
}
.card-header.selected {
  background-color: #3298dc;
  border-radius: 0;
}

.card-header.selected .card-header-title {
  color: white;
}
button {
  border: 0;
  background: transparent;
}

.list {
  background-color: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 3px hsla(0, 0%, 4%, .1), 0 0 0 1px hsla(0, 0%, 4%, .1);
  box-shadow: 0 2px 3px hsla(0, 0%, 4%, .1), 0 0 0 1px hsla(0, 0%, 4%, .1)
}

.list-item {
  display: flex;
  align-items: flex-end;
  padding: 0.6em .6em;
  margin: 0;
}

.list-item.is-small {

}

.list-item.is-small label {

}

.list-item:not(a) {
  color: #4a4a4a
}

.list-item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px
}

.list-item:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px
}

.list-item:not(:last-child) {
  border-bottom: 1px solid #dbdbdb
}

.list-item.is-active {
    background-color: #01a3a4;
    color: #fff
}

a.list-item {
  background-color: #f5f5f5;
  cursor: pointer
}

.thesaurus.selected {
  background-color: #3298dc;
  color: white;
}

.thesaurus input {

}

.thesaurus label {
  font-size: 14px;
  line-height: 14px;
  vertical-align: baseline;
}
</style>
