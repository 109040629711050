<template>
  <div>
    <title-bar :title-stack="titleStack"/>
    <section class="section is-main-section">
      <div class="columns">
        <div class="column">
          <b-field class="file is-primary" :class="{'has-name': !!file}">
            <b-upload v-model="file" @input="uploadPictoFile" class="file-label">
              <span class="file-cta">
                <i class="fa-light fa-upload"></i>
              </span>
              <span class="file-name" v-if="file">
                {{ file.name }}
              </span>
            </b-upload>
          </b-field>
          <img
            v-if="picto"
            :src="baseUrl + pictoUrl + picto.nomImage"
            :alt="picto.alias"
            class="picto-file"
          />
          <div class="field">
            <div class="control has-icons-left is-clearfix">
              <input type="text" class="input" style="border: 0; box-shadow: none;" placeholder="Ajouter un categorie de picto" v-model="newCategoryPicto" @keyup.enter="addCategoryPicto">
              <span class="icon is-left"><i class="fa-light fa-layer-plus"></i></span>
            </div>
          </div>
          <ul class="categories" v-if="!!loadData">
            <li class="category field" :key="g.idCategoriePicto" v-for="g in pictos" :class="{editing: g === editingCategoryPicto}">
              <div class="level category-header" style="margin-top: 1.5rem;">
                <div class="level-left">
                  <!-- <input type="checkbox" v-model="g.completed" class="input"> -->
                  <div class="level-item">
                    <div class="view">
                      <label class="label" @dblclick="editCategoryPicto(g)">{{ g.libCategoriePicto }}</label>
                    </div>
                    <input type="text" class="editCategoryPicto" v-model="g.libCategoriePicto" @keyup.enter="doneEditCategoryPicto(g)" @blur="doneEditCategoryPicto(g)" @keyup.esc="cancelEditCategoryPicto(g)" v-focus="g === editingCategoryPicto">
                  </div>
                </div>
              </div>
              <div class="taginput" v-sortable="{ sortableOptions, onUpdate: orderPicto }">
                <div class="taginput-container is-focusable">
                  <div class="tag" :name="'picto-' + f.idPicto" :key="f.idPicto" v-for="f in g.picto" :class="{selected: f.selected, completed: f.completed, editing: f === editingPicto}">
                    <div class="view">
                      <label @click="selectPicto(f)" @dblclick="editPicto(f)">{{ f.alias }}</label>
                      <button class="destroy" @click="deletePicto(f,g)"><i class="fal fa-times"></i></button>
                    </div>
                    <input type="text" class="editPicto" v-model="f.alias" @keyup.enter="doneEditPicto(f)" @blur="doneEditPicto(f)" @keyup.esc="cancelEditPicto(f)" v-focus="f === editingPicto">
                  </div>
                  <div>
                    <div class="control has-icons-left is-clearfix">
                      <input type="text" class="input" style="padding-top: 0; padding-bottom: 0; border: 0; box-shadow: none;" autofocus v-model="newPicto[g.idCategoriePicto]" @keyup.enter="addPicto(g.idCategoriePicto)" placeholder="Ajouter un picto">
                      <span class="icon is-left"><i class="fa-light fa-layer-plus"></i></span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="column" style="margin-top: 42px;">
          <b-collapse class="card" animation="slide" v-for="(type, index) of thesaurusType" :key="index" :open="false" @open="isOpen = index">
            <template #trigger="props">
              <div class="card-header" role="button" :class="{ selected: type.selected }">
                <p class="card-header-title">{{ type.type | spaceUppercase }}</p>
                <a class="card-header-icon">
                  <b-icon pack="fal" :icon="props.open ? 'angle-up' : 'angle-down'" size="is-small"></b-icon>
                </a>
              </div>
            </template>
            <div class="card-content">
              <ul class="list is-hoverable">
                <li class="list-item is-small thesaurus" :class="{ selected: t.selected }" :key="t.id" v-for="t in getFilteredThesaurus(thesaurus, type.type)">
                  <input :id="'codeApidae-' + t.codeApidae" type="checkbox" :value="t.codeApidae" v-model="idsPictoCodeTif" class="toggle" @click="changePictoCodeTif($event)">
                  <label :for="'codeApidae-' + t.codeApidae">{{ t.labelFR }} <span v-if="t.parent">({{ t.parent }})</span></label>
                </li>
              </ul>
            </div>
          </b-collapse>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'

import _ from 'lodash'
import Sortable from 'sortablejs'
import Vue from 'vue'

const $httpRacineApi = 'https://www.sainte-maxime.com'
const $pictoUrl = '/assets/img/pictos/'

const createSortable = (el, options) => {
  return Sortable.create(el, {
    ...options
  })
}

/**
* We add a new instance of Sortable when the element
* is bound or updated, and destroy it when it's unbound.
*/
const sortable = {
  name: 'sortable',
  bind (el, binding) {
    const container = el.querySelector('.taginput-container')
    container._sortable = createSortable(container, binding.value)
  },
  update (el, binding) {
    const container = el.querySelector('.taginput-container')
    container._sortable.destroy()
    container._sortable = createSortable(container, binding.value)
  },
  unbind (el) {
    const container = el.querySelector('.taginput-container')
    container._sortable.destroy()
  }
}

export default {
  name: 'Pictos',
  components: { TitleBar },
  data () {
    return {
      baseUrl: $httpRacineApi,
      pictoUrl: $pictoUrl,
      file: null,
      pictos: [],
      oldPictoSelected: '',
      picto: '',
      pictoCodeTif: [],
      idsPictoCodeTif: [],
      thesaurusSelected: [],
      thesaurusTypeSelected: [],
      newCategoryPicto: '',
      newPicto: [],
      editingCategoryPicto: null,
      editingPicto: null,
      sortableOptions: {
        chosenClass: 'is-primary',
        draggable: '.tag',
        picto: '.ignore-elements'
      },
      loadData: false,
      f: null,
      thesaurus: null,
      thesaurusType: null,
      isOpen: false
    }
  },
  mounted: function () {
    var self = this
    fetch($httpRacineApi + '/pictos/getPictos')
      .then((response) => response.json())
      .then((jsonResponse) => {
        // console.log(jsonResponse)
        self.pictos = jsonResponse
        self.loadData = true
      })
      .catch(function (e) {
        console.error(e)
      })

    fetch($httpRacineApi + '/thesaurus/getThesaurus')
      .then((response) => response.json())
      .then((jsonResponse) => {
        //   console.log(jsonResponse)
        // self.thesaurusType = _.keys(
        //   _.countBy(jsonResponse, function (jsonResponse) {
        //     return jsonResponse.type;
        //   })
        // );
        const key = 'type'
        var thesaurusTypeTemp = [...new Map(jsonResponse.map(item =>
          [item[key], item])).values()]
        self.thesaurusType = thesaurusTypeTemp.map(({ type }) => ({ type }))
        // console.log(self.thesaurusType)

        // jsonResponse.map( index => index.selected=false)
        self.thesaurus = jsonResponse
      })
      .catch(function (e) {
        console.error(e)
      })
  },
  // watch: {
  //   value (value) {
  //     this.pictos = value
  //   }
  // },
  methods: {
    getFilteredThesaurus (thesaurus, type) {
      // console.log(_.filter(thesaurus, { type: type }))
      return _.filter(thesaurus, { type: type })
    },
    changePictoCodeTif (event) {
      // console.log(event)
      const idPictoCodeTif = event.target.value

      if (event.target.checked) { // addPictoCodeTif
        // console.log('addPictoCodeTif')
        fetch($httpRacineApi + '/pictos/addPictoCodeTif', {
          method: 'POST',
          body: JSON.stringify({
            idPicto: this.picto.idPicto,
            alias: this.picto.alias,
            idPictoCodeTif: idPictoCodeTif
          }),
          headers: {
            'Content-type': 'application/json; chartset=UTF-8'
          }
        })
          .then(response => response.json())
          .then((jsonResponse) => {

          })
      } else { // removePictoCodeTif
        // console.log('removePictoCodeTif')
        fetch($httpRacineApi + '/pictos/removePictoCodeTif', {
          method: 'POST',
          body: JSON.stringify({
            idPicto: this.picto.idPicto,
            idPictoCodeTif: idPictoCodeTif
          }),
          headers: {
            'Content-type': 'application/json; chartset=UTF-8'
          }
        })
          .then(response => response.json())
          .then((jsonResponse) => {

          })
      }
    },
    uploadPictoFile () {
      if (this.picto) {
        const formData = new FormData()
        formData.append('file', this.file)
        formData.append('id', this.picto.idPicto)
        fetch($httpRacineApi + '/pictos/uploadPictoFile', {
          method: 'POST',
          body: formData
        })
          .then((response) => response.json())
          .then((jsonResponse) => {
            console.log(jsonResponse)
            this.picto.nomImage = jsonResponse
            this.file = null
          })
      } else {
        console.log('Veuillez sélectionner un picto')
        this.file = null
      }
    },
    addPicto (idCategoriePicto) {
      var value = this.newPicto[idCategoriePicto] && this.newPicto[idCategoriePicto].trim()
      if (!value) {
        return
      }
      fetch($httpRacineApi + '/pictos/addPicto', {
        method: 'POST',
        body: JSON.stringify({
          alias: value,
          idCategoriePicto: idCategoriePicto
        }),
        headers: {
          'Content-type': 'application/json; chartset=UTF-8'
        }
      })
        .then(response => response.json())
        .then((jsonResponse) => {
          this.pictos.filter(f => f.idCategoriePicto === idCategoriePicto)[0].picto.push({
            idPicto: jsonResponse.idPicto,
            alias: value,
            pictoCodeTif: [],
            selected: false
          })
        })
        // console.log(this.pictos)
      this.newPicto = []
    },
    selectPicto (f) {
      // console.log(f)
      f.selected = true
      if (!!this.oldPictoSelected && this.oldPictoSelected !== f) this.oldPictoSelected.selected = false
      this.oldPictoSelected = f

      this.picto = f
      this.pictoCodeTif = f.pictoCodeTif
      this.idsPictoCodeTif = this.pictoCodeTif.map(q => parseInt(q.codeApidae))
      // console.log(this.idsPictoCodeTif)
      // console.log(this.thesaurusType)

      this.thesaurusSelected.map(t => { t.selected = false }) // delete old
      this.thesaurusSelected = this.thesaurus.filter((h) => this.idsPictoCodeTif.includes(parseInt(h.codeApidae)))
      this.thesaurusSelected.forEach(t => {
        t.selected = true
      })
      // console.log(this.thesaurusSelected)
      this.thesaurusType.map(t => { t.selected = false }) // delete old
      this.thesaurusTypeSelected = this.thesaurusSelected.map(m => m.type)
      this.thesaurusType.forEach(s => {
        if (this.thesaurusTypeSelected.includes(s.type)) {
          s.selected = true
        }
      })
      // console.log(this.thesaurusTypeSelected)
      // console.log(this.thesaurusType)
    },
    deletePicto: function (f, g) {
      fetch($httpRacineApi + '/pictos/deletePicto', {
        method: 'POST',
        body: JSON.stringify({
          idPicto: f.idPicto
        }),
        headers: {
          'Content-type': 'application/json; chartset=UTF-8'
        }
      })
      const indexPicto = this.pictos.filter(x => x.idCategoriePicto === g.idCategoriePicto)[0].picto.findIndex(x => x.idPicto === f.idPicto)
      this.pictos.filter(x => x.idCategoriePicto === g.idCategoriePicto)[0].picto.splice(indexPicto, 1)
    },
    editPicto (f) {
      this.beforeEditCache = f.alias
      this.editingPicto = f
    },
    doneEditPicto: function (f) {
      if (!this.editingPicto) {
        return
      }
      this.editingPicto = null
      f.alias = f.alias.trim()
      if (!f.alias) {
        this.deletePicto(f)
      }
      fetch($httpRacineApi + '/pictos/editPicto', {
        method: 'POST',
        body: JSON.stringify({
          alias: f.alias,
          idPicto: f.idPicto
        }),
        headers: {
          'Content-type': 'application/json; chartset=UTF-8'
        }
      })
    },
    cancelEditPicto: function (f) {
      this.editingPicto = null
      f.alias = this.beforeEditCache
    },
    orderPicto (event) {
      const orderArray = []
      const elements = event.target.childNodes
      elements.forEach(function (element) {
        if (element.firstChild !== null && element.hasAttribute('name')) {
          orderArray.push(element.getAttribute('name').replace('picto-', ''))
        }
      })
      const orderString = orderArray.join('-')
      fetch($httpRacineApi + '/pictos/orderPicto', {
        method: 'POST',
        body: JSON.stringify({
          orderPictos: orderString
        }),
        headers: {
          'Content-type': 'application/json; chartset=UTF-8'
        }
      })
    },
    addCategoryPicto () {
      var value = this.newCategoryPicto && this.newCategoryPicto.trim()
      if (!value) {
        return
      }
      fetch($httpRacineApi + '/pictos/addCategoriePicto', {
        method: 'POST',
        body: JSON.stringify({
          libCategoriePicto: value
        }),
        headers: {
          'Content-type': 'application/json; chartset=UTF-8'
        }
      })
        .then(response => response.json())
        .then((jsonResponse) => {
          this.pictos = Object.assign({}, this.pictos, [{
            picto: [],
            idCategoriePicto: jsonResponse.idCategoriePicto,
            libCategoriePicto: value,
            alias: value,
            tri: '0'
          }])
          document.location.reload()
        })
      this.newCategoryPicto = ''
    },
    editCategoryPicto (g) {
      this.beforeEditCache = g.libCategoriePicto
      this.editingCategoryPicto = g
    },
    doneEditCategoryPicto: function (g) {
      if (!this.editingCategoryPicto) {
        return
      }
      this.editingCategoryPicto = null
      g.libCategoriePicto = g.libCategoriePicto.trim()
      if (!g.libCategoriePicto) {
        this.deletePicto(g)
      }

      fetch($httpRacineApi + '/pictos/editCategoriePicto', {
        method: 'POST',
        body: JSON.stringify({
          libCategoriePicto: g.libCategoriePicto,
          idCategoriePicto: g.idCategoriePicto
        }),
        headers: {
          'Content-type': 'application/json; chartset=UTF-8'
        }
      })
    },
    cancelEditCategoryPicto: function (g) {
      this.editingCategoryPicto = null
      g.libCategoriePicto = this.beforeEditCache
    }
  },
  directives: {
    focus (el, value) {
      if (value) {
        Vue.nextTick(() => {
          el.focus()
        })
      }
    },
    sortable
  },
  computed: {
    titleStack () {
      return [
        'Pictos'
      ]
    }
  },
  filters: {
    spaceUppercase: value => {
      if (!value) return ''
      return value.replace(/([A-Z])/g, ' $1').trim()
    }
  }
}
</script>
<style scoped>
.apidae_page_apidae_pictos_plugin_pictos {
  background-color: transparent;
}

.fa-layer-plus {
  width: 21px;
}

.picto-file {
  position: fixed;
  bottom: 10px;
  max-width: 60px;
  right: 0;
  padding: 10px;
  background: white;
  border: 1px solid #ccc;
  background-color: #ccc;
  z-index: 10;
}

/* CategoryPicto */
.category .level-item button {
  cursor: pointer;
}
.category .category-header .editCategoryPicto {
  display: none;
}
.category .category-header .view {
  display: flex;
}
.category.editing .category-header .editCategoryPicto {
  display: block;
  min-height: 24px;
  line-height: 1;
}
.category.editing .category-header .view {
  display: none;
}

/* Picto */
.tag.selected {
  background-color: #7957d5;
  color: #fff;
}
.tag.selected i {
  color: #fff;
}
 .editPicto {
  display: none;
}
.tag .view {
  display: flex;
}
.tag.editing .editPicto {
  display: block;
  min-height: 24px;
  line-height: 1;
}
.tag.editing .view {
  display: none;
}

.card-header-title {
  font-size: 1rem;
  font-weight: 600 !important;
}
.card {
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
}
.switch input[type=checkbox]+.check:before {
  width: 16px;
  height: 16px;
}
.card-header.selected {
  background-color: #3298dc;
  border-radius: 0;
}

.card-header.selected .card-header-title {
  color: white;
}
button {
  border: 0;
  background: transparent;
}

.list {
  background-color: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 3px hsla(0, 0%, 4%, .1), 0 0 0 1px hsla(0, 0%, 4%, .1);
  box-shadow: 0 2px 3px hsla(0, 0%, 4%, .1), 0 0 0 1px hsla(0, 0%, 4%, .1)
}

.list-item {
  display: flex;
  align-items: flex-end;
  padding: 0.6em .6em;
  margin: 0;
}

.list-item.is-small {

}

.list-item.is-small label {

}

.list-item:not(a) {
  color: #4a4a4a
}

.list-item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px
}

.list-item:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px
}

.list-item:not(:last-child) {
  border-bottom: 1px solid #dbdbdb
}

.list-item.is-active {
  background-color: #01a3a4;
  color: #fff
}

a.list-item {
  background-color: #f5f5f5;
  cursor: pointer
}

.thesaurus.selected {
  background-color: #3298dc;
  color: white;
}

.thesaurus input {

}

.thesaurus label {
  font-size: 14px;
  line-height: 14px;
  vertical-align: baseline;
}
</style>
