<template>
  <div>
    <title-bar :title-stack="titleStack"/>
    <section class="section is-main-section">
      <div class="columns">
        <div class="column">
          <ul class="list is-hoverable">
            <li class="list-item prestas" v-for="p in prestas" :key="p.prestaVO.idPrestation">
              <div class="tag" :class="{selected: p.selected, order: p.prestaVO.orderImages, active: p.prestaVO.activeImages}">
                <div class="view">
                  <!-- <input :id="'idPrestation-' + p.prestaVO.idPrestation" type="checkbox" :value="p.prestaVO.idPrestation" class="toggle"> -->
                  <label @click="selectPresta(p)" :class="'idPrestation-' + p.prestaVO.idPrestation">{{ p.title }} (<a :href="p.url" target="_blank">{{ p.prestaVO.idPrestation }}</a>)</label>
                  <button class="reset" @click="resetImages(p)" v-if="p.prestaVO.orderImages || p.prestaVO.activeImages"><i class="fal fa-times"></i></button>
                </div>
              </div>
            </li>
          </ul>
          <div class="paginate">
            <div v-show="loadPaginate" class="loading"></div>
          </div>
        </div>
        <div class="column sticky">
          <div v-show="prestaSelectedIdPrestation == p.prestaVO.idPrestation" v-for="p in prestasHasImg" :key="p.prestaVO.idPrestation">
            <div class="taginput" v-sortable="{ sortableOptions, onUpdate: orderImages }">
              <div class="taginput-container is-focusable">
                <div class="tag" :class="{active: i.active}" :name="'image-' + i.index" :key="name" v-for="(i, name) in p.prestaVO.images">
                  <div class="view">
                    <b-image
                      :src="i.thumb"
                      :alt="i.legende"
                    ></b-image>
                    <div class="field">
                      <input type="checkbox" v-model="i.active" :name="'image-' + i.index" @change="activeImages($event)">
                    </div>
                    <!-- <b-field>
                      <b-checkbox v-model="i.active" :name="'image-' + i.index" @click.native="activeImages($event)"></b-checkbox>
                    </b-field> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'

import Sortable from 'sortablejs'

const $httpRacineApi = 'https://www.sainte-maxime.com'

const createSortable = (el, options) => {
  return Sortable.create(el, {
    ...options
  })
}

/**
* We add a new instance of Sortable when the element
* is bound or updated, and destroy it when it's unbound.
*/
const sortable = {
  name: 'sortable',
  bind (el, binding) {
    const container = el.querySelector('.taginput-container')
    container._sortable = createSortable(container, binding.value)
  },
  update (el, binding) {
    const container = el.querySelector('.taginput-container')
    container._sortable.destroy()
    container._sortable = createSortable(container, binding.value)
  },
  unbind (el) {
    const container = el.querySelector('.taginput-container')
    container._sortable.destroy()
  }
}

export default {
  name: 'Prestas',
  components: { TitleBar },
  data () {
    return {
      loadData: false,
      loadPaginate: false,
      params: {},
      prestas: [],
      prestaSelected: '',
      prestaSelectedIdPrestation: null,
      oldPrestaSelected: '',
      sortableOptions: {
        chosenClass: 'is-primary',
        draggable: '.tag'
      }
    }
  },
  mounted: function () {
    var self = this

    fetch($httpRacineApi + '/services/getPrestas')
      .then((response) => response.json())
      .then((data) => {
        self.params = data.params
        self.prestas = data.items
        self.loadData = true
      })
      .catch(function (e) {
        console.error(e)
      })

    window.onscroll = () => {
      const scrollTop = window.scrollY
      const browserHeight = window.innerHeight
      const offset = document.querySelector('.paginate').offsetTop
      if (offset !== null && (offset - browserHeight <= scrollTop) && this.loadPaginate === false && parseInt(this.params.curPage) < parseInt(this.params.nbPages)) {
        const url = this.params.nextPage
        this.callPaginate(url)
      }
    }
  },
  methods: {
    callPaginate: function (url) {
      if (this.loadPaginate === false) {
        this.loadPaginate = true

        fetch(url)
          .then((response) => response.json())
          .then((data) => {
            this.params = data.params
            this.prestas = [...this.prestas, ...data.items]
            this.loadPaginate = false
          })
          .catch((error) => console.error(error))
          .finally()
      }
    },
    selectPresta (p) {
      // console.log(p)
      p.selected = true
      if (!!this.oldPrestaSelected && this.oldPrestaSelected !== p) this.oldPrestaSelected.selected = false
      this.prestaSelected = p
      this.oldPrestaSelected = this.prestaSelected

      this.prestaSelectedIdPrestation = this.prestaSelected.prestaVO.idPrestation
    },
    orderImages (event) {
      const orderArray = []
      const elements = event.target.childNodes
      elements.forEach(function (element) {
        orderArray.push(element.getAttribute('name').replace('image-', ''))
      })
      const orderString = orderArray.join('-')
      fetch($httpRacineApi + '/images/orderImages', {
        method: 'POST',
        body: JSON.stringify({
          idPrestation: this.prestaSelected.prestaVO.idPrestation,
          orderImages: orderString
        }),
        headers: {
          'Content-type': 'application/json; chartset=UTF-8'
        }
      })
      this.prestas.filter(presta => presta.prestaVO.idPrestation === this.prestaSelected.prestaVO.idPrestation).map(presta => { presta.prestaVO.orderImages = true })
    },
    activeImages (event) {
      console.log(event)
      const element = event.target
      const indexImage = element.getAttribute('name').replace('image-', '')
      const checkedImage = element.checked

      fetch($httpRacineApi + '/images/activeImage', {
        method: 'POST',
        body: JSON.stringify({
          idPrestation: this.prestaSelected.prestaVO.idPrestation,
          indexImage: indexImage,
          checkedImage: checkedImage
        }),
        headers: {
          'Content-type': 'application/json; chartset=UTF-8'
        }
      })
      this.prestas.filter(presta => presta.prestaVO.idPrestation === this.prestaSelected.prestaVO.idPrestation).map(presta => { presta.prestaVO.activeImages = true })
    },
    resetImages (p) {
      fetch($httpRacineApi + '/images/resetImages', {
        method: 'POST',
        body: JSON.stringify({
          idPrestation: p.prestaVO.idPrestation
        }),
        headers: {
          'Content-type': 'application/json; chartset=UTF-8'
        }
      })
      this.prestas.filter(presta => presta.prestaVO.idPrestation === p.prestaVO.idPrestation).map(presta => {
        presta.prestaVO.orderImages = false
        presta.prestaVO.activeImages = false
      })
    }
  },
  computed: {
    prestasHasImg: function () {
      return this.prestas.filter(p => p.prestaVO.images.length > 0)
    },
    titleStack () {
      return [
        'Prestations'
      ]
    }
  },
  directives: {
    sortable
  }
}
</script>
<style scoped>
  .columns {
    align-items: flex-start;
  }
  .sticky {
    position: sticky;
    top: 30px;
  }
  .list-item {
    display: flex;
    align-items: flex-end;
    padding: 0.3em .0em;
    margin: 0;
  }
  .list-item:not(a) {
    color: #4a4a4a
  }
  .list-item.is-active {
    background-color: #01a3a4;
    color: #fff
  }
  a.list-item {
    background-color: #f5f5f5;
    cursor: pointer
  }
  .tag.order, .tag.active {
    position: relative;
    background-color: #f1466880;
  }
  .tag .reset {
    position: absolute;
    top: 0;
    right: -20px;
    height: 24px;
    width: 24px;
    border: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background: #f14668;
    cursor: pointer;
  }
  .tag .reset:hover {
    background: #ee1742;
  }
  .tag.selected {
    background-color: #7957d5;
    color: #fff;
  }
  .tag.selected a {
    color: #fff;
  }
  .tag:not(.active) img {
    opacity: .65;
  }
  .taginput .view {
    position: relative;
  }
  .taginput .field {
    position: absolute;
    top: 6px;
    right: 4px;
  }
  .taginput .taginput-container>.tag, .taginput .taginput-container>.tags {
    height: 153px;
    cursor: pointer;
  }
  .image {
    width: 120px !important;
    border-radius: 4px !important;
    overflow: hidden;
  }
</style>
